<template>
  <Single
    v-if="!multiple"
    v-model="computedData"
    v-bind:title="title"
    v-bind:icon="icon"
    v-bind:buttonText="buttonText"
    v-bind:dragText="dragText"
    v-bind:orText="orText"
    v-bind:sizeLimit="sizeLimit"
    v-bind:sizeLimitMessage="sizeLimitMessage"
    v-bind:metaVisible="metaVisible"
    v-bind:mimeBmp="mimeBmp"
    v-bind:mimeJpeg="mimeJpeg"
    v-bind:mimePng="mimePng"
    v-bind:mimeGif="mimeGif"
    v-bind:mimeWebp="mimeWebp"
    v-bind:mimeTiff="mimeTiff"
    v-bind:mimeSvg="mimeSvg"
    v-bind:mimeMp4="mimeMp4"
    v-bind:mimeMpeg="mimeMpeg"
    v-bind:mimeOgg="mimeOgg"
    v-bind:mimeWebm="mimeWebm"
    v-bind:videoAutoPlay="videoAutoPlay"
    v-bind:imageRequired="imageRequired"
    v-bind:fixedMetaTitle="fixedMetaTitle"
    v-bind:fixedMetaAlt="fixedMetaAlt"
    v-bind:hideDropzone="hideDropzone"
    ref="single"
  />
  <Multiple
    v-else
    v-model="computedData"
    v-bind:title="title"
    v-bind:icon="icon"
    v-bind:buttonText="buttonText"
    v-bind:dragText="dragText"
    v-bind:orText="orText"
    v-bind:sizeLimit="sizeLimit"
    v-bind:sizeLimitMessage="sizeLimitMessage"
    v-bind:countLimit="countLimit"
    v-bind:countLimitMessage="countLimitMessage"
    v-bind:metaVisible="metaVisible"
    v-bind:mimeBmp="mimeBmp"
    v-bind:mimeJpeg="mimeJpeg"
    v-bind:mimePng="mimePng"
    v-bind:mimeGif="mimeGif"
    v-bind:mimeWebp="mimeWebp"
    v-bind:mimeTiff="mimeTiff"
    v-bind:mimeSvg="mimeSvg"
    v-bind:mimeMp4="mimeMp4"
    v-bind:mimeMpeg="mimeMpeg"
    v-bind:mimeOgg="mimeOgg"
    v-bind:mimeWebm="mimeWebm"
    v-bind:videoAutoPlay="videoAutoPlay"
    v-bind:imageRequired="imageRequired"
    v-bind:fixedMetaTitle="fixedMetaTitle"
    v-bind:fixedMetaAlt="fixedMetaAlt"
    ref="multiple"
  />
</template>

<script>
const Single = () => import("./components/Single/Index");
const Multiple = () => import("./components/Multiple/Index");

export default {
  name: "WeImageUploader",
  components: {
    Single,
    Multiple,
  },
  props: {
    data: {
      files: [],
      filesData: null,
    },
    multiple: {
      default: "",
    },
    title: {
      default: "Görsel Yükle",
    },
    icon: {
      default() {
        let result = "fas fa-cloud-upload-alt fa-3x text-secondary";

        if (this.multiple) {
          result = "fas fa-image";
        }

        return result;
      },
    },
    buttonText: {
      default: "Dosya Seç",
    },
    dragText: {
      default: "Dosya Sürükle & Bırak",
    },
    orText: {
      default: "veya",
    },
    sizeLimit: {
      default: "1024",
    },
    sizeLimitMessage: {
      default: "En fazla <b>1024 KB</b> boyutunda dosya yükleyebilirsiniz.",
    },
    countLimit: {
      default: "10",
    },
    countLimitMessage: {
      default: "En fazla 10 adet dosya yükleyebilirsiniz.",
    },
    metaVisible: {
      default: "true",
    },
    hideDropzone: {
      default: false,
    },
    mimeBmp: {
      default: "",
    },
    mimeJpeg: {
      default: "true",
    },
    mimePng: {
      default: "true",
    },
    mimeGif: {
      default: "true",
    },
    mimeWebp: {
      default: "true",
    },
    mimeTiff: {
      default: "",
    },
    mimeSvg: {
      default: "true",
    },
    mimeMp4: {
      default: "true",
    },
    mimeMpeg: {
      default: "true",
    },
    mimeOgg: {
      default: "true",
    },
    mimeWebm: {
      default: "true",
    },
    videoAutoPlay: {
      default: "true",
    },
    imageRequired: {
      default: "",
    },
    fixedMetaTitle: {
      default: "",
    },
    fixedMetaAlt: {
      default: "",
    },
  },
  model: {
    prop: "data",
  },
  computed: {
    computedData() {
      return this.data;
    },
  },
};
</script>

<style lang="scss">
.uploaded-media {
  align-items: center;
}

.uploaded-image-body {
  max-height: 316px;
  overflow-x: hidden !important;
  overflow-y: auto;
}

.uploaded-card,
.uploaded-image-card {
  border-radius: 0.4rem;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.uploaded-image-card.featured {
  border: 2px solid #2ed573;
  background-color: #2ed5740e;
  margin-top: 10px;
  box-shadow: 1px 3px 5px rgba(128, 128, 128, 0.295);
}

.uploaded-image-card:not(:last-child) {
  margin-bottom: 1rem;
}

.uploaded-media .media-body span {
  font-size: 14px;
  display: block;
}

.uploaded-media img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  background-color: #fff;
  border: 2px solid #2ed573;
  border-radius: 0.25rem !important;
}
</style>
